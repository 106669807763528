#home-header {
    position: relative;
    height: 50vh;
    background-size: cover;
    background-position: center center;

    @include media-breakpoint-up(lg) {
        height: 100vh;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%);
        background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%);
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#4dffffff',GradientType=0 );
    }

    #home-payoff {
        font-family: Bebas Neue, sans-serif;
        position: absolute;
        z-index: 20;
        top: 40%;
        left: 15px;
        color: $primary;


        @include media-breakpoint-up(lg) {
            top: 20%;
            left: 10%;
        }

        #home-payoff-title {
            font-size: 35px;
        }

        #home-payoff-subtitle {
            font-size: 19px;
        }

        @include media-breakpoint-up(lg) {
            #home-payoff-subtitle {
                font-size: 27px;
            }

            #home-payoff-title {
                font-size: 60px;
            }
        }
    }
}

#home-certificates-images {

    @include media-breakpoint-down(md) {
        background: #fff;
    }

    .certificate-image-home {
        max-width: 100%;
        max-height: 80px;
        @include media-breakpoint-up(lg) {
            height: 80px;
        }
    }
}
