div.cookie_notice[role="status"] {
    display: none;
    position: fixed;
    left:0px;
    z-index:100000;
    background: #fff none repeat scroll 0 0;
    font-size: 11px;
}

div[role="status"] {
    box-sizing: border-box;
    color: #333;
    padding: 10px 32px;
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 16px;
}
div[role="status"] a {
    color: #333;
    font-size: 11px;
    font-family: arial;
}

div[role="status"] span {
    font-weight: bold;
    text-decoration: underline;
}

div[role="status"] span:hover {
    color: #AA0331;
    cursor: pointer;
    text-decoration: none;
}

div[role="status"] .closer .close_eu {
    font-weight: bold;
    cursor: pointer;
    position: relative;
    margin-top: 5px;
    text-decoration: none;
    width: 100px;
    opacity: 1.0;
    text-shadow: none;
    font-size: 14px;
    color:#333;
    line-height: 1;
    font-size: 11px;
}

#gfcookielaw h4 {
    margin-top: 10px
}
