//LAYOUT
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
  xxl: 1800px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px,
  xxl: 1700px
) !default;

$border-radius: 0px;
$input-border-radius: 0px;
$input-border-radius-lg: 0px;
$input-border-radius-sm: 0px;

$spacer: 1rem;

$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 2),
    5: ($spacer * 4),
    10: ($spacer * 5),
    20: ($spacer * 10)
  ),
  $spacers
);

$sizes: () !default;
$sizes: map-merge(
  (
    10: 10%,
    20: 20%,
    25: 25%,
    30: 30%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

//TYPOGRAPHY
$enable-responsive-font-sizes: true;
$headings-font-weight: normal;

//COLORS
$body-color: #474747;
$link-color: $body-color;

$primary: #1c5893;
$secondary: #527caa;
$warning: #fcf8e3;
$danger: #dc3545;
$gray-100: #f8f8f9;
$gray-900: #212529;

//BUTTONS
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;

//BREADCRUMBS
$breadcrumb-font-size: 0.8rem;
$breadcrumb-margin-bottom:0px;
$breadcrumb-padding-x: 0rem;
$breadcrumb-padding-y: 0.4rem;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: #fff;
$breadcrumb-active-color: #fff;

//NAVBAR
$navbar-padding-y: $spacer/1.5;
$navbar-brand-padding-y: 0px;
$navbar-nav-link-padding-x: 1.5rem !default;
$nav-link-padding-y: 1rem;
