#footer {
    font-size: 0.8rem;
}

#legal {
    font-size: 0.7rem;
}

.text-tostroke-wrapper:hover .text-tostroke {
    -webkit-text-stroke: 1px #fff;
    color: transparent;
}

.text-tostroke {
    @include transition();
    -webkit-text-stroke: 0px #fff;
    color: #fff;

    &:hover {
        -webkit-text-stroke: 1px #fff;
        color: transparent;
        @include transition();
    }
}

.text-stroked-wrapper:hover .text-stroked {
    -webkit-text-stroke: 1px transparent;
    color: #fff;
}

.text-stroked {
    @include transition();
    -webkit-text-stroke: 1px #fff;
    color: transparent;

    &:hover {
        -webkit-text-stroke: 1px transparent;
        color: #fff;
        @include transition();
    }
}


.bebas {
    font-family: Bebas Neue, sans-serif;
}

@media screen and (max-width: 911px) {
    div[role="status"] .closer .close_eu {
        margin-top: 15px;
    }
}
