.acf-map {
    height: 200px;
    @include media-breakpoint-up(lg) {
        height: 500px;
    }
}

.grecaptcha-badge {
    display: none;
}
