#qtranslate-chooser {
    list-style: none;
    display: inline-block;
    margin: 0px;
    li {
        float: left;
        height: 40px;
        width: 50px;
        padding: 0px 0px;
        margin-left: 0.5rem;
        line-height: 26px;
        text-align: center;
        color: #1c5893;
        border: 1px solid #dcdcdc;
        font-size: 20px;
        a {
            display: block;
            height: 38px;
            line-height: 38px;
            @include transition(all 0.5s);
            &:hover {
                background: #eee;
            }
        }
    }

}

.qtranxs_widget_end {
    display: none;
}
