* {
    outline: none;
}

body {
    font-size: 0.9rem;
    font-family: 'Roboto Condensed', sans-serif;
}

@include media-breakpoint-up(md) {
    body {
        font-size: 1.1rem;
        font-weight: 300;
    }
}

@include media-breakpoint-up(lg) {
    .col-lg-25 {
        flex: 0 0 20.0%
    }
}

.anchor-hash {
    padding-top: 70px;
    margin-top: -70px;

    @include media-breakpoint-up(lg) {
        padding-top: 120px;
        margin-top: -120px;
    }
}

.page-title {
    position: relative;
    &:before {
        content: '';
        width: 70%;
        height: 100%;
        top: 0px;
        position: absolute;
        background: rgb(47,88,142);
        background: linear-gradient(90deg, rgba(47,88,142,1) 40%, rgba(47,88,142,0) 100%);
        z-index: 10;
    }

    h1 {
        position: relative;
        z-index: 20;
    }
}

#page-content {
    a {
        text-decoration: underline;
        color: $primary;

        &:hover {

        }
    }

    h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
        color: $primary;
    }
}
