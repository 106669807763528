.navbar {
    @include media-breakpoint-down(md) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.navbar-toggler {
    @include media-breakpoint-down(md) {
        margin-right: $spacer;
        margin-bottom: 9.6px;
    }
}

.navbar-icon {
    height: 40px;
    width: 50px;
    padding: 5px 0;
    margin-left: $spacer/2;
    line-height: 30px;
    text-align: center;
    color: $primary;
    border:1px solid #dcdcdc;
    font-size: 20px;
}

.navbar-brand img {
    @include transition();

    @include media-breakpoint-down(md) {
        height: 40px;
        padding-left: $spacer;
    }

    @include media-breakpoint-up(md) {
        height: 55px
    }
}

.nav-link {
    @include media-breakpoint-down(md) {
        border-bottom: 1px solid #dcdcdc;
        padding-left: 15px !important;
        padding-right: 15px !important;
        color: #fff !important;
    }
}

#menu-main_menu {
    @include media-breakpoint-down(lg) {
        font-size: 1.1rem;
        background: $primary;
        margin-top: $spacer
    }
}

@include media-breakpoint-up(lg) {

    #menu-principale {
        display: none !important;
    }

    #menu-burgher {
        float: right;
        margin-left: 15px;
        width: 40px;
        height:40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        border:1px solid white;

        div {
            width: 38px;
            height: 6px;
            border-top: 2px solid white;
            border-bottom: 2px solid white;
            background: $primary;
            @include transition();
        }

        &:hover {
            div:nth-child(1),
            div:nth-child(4) {
                width: 10px;
            }

            div:nth-child(4) {
               transform: translateX(30px);
            }
        }

        &.toggled {
            @include transition();
            div:nth-child(1) ,
            div:nth-child(4) {
                display: none;
            }

            div:nth-child(2) {
                transform: rotate(45deg) translateY(17px) translateX(6px);
                width: 54px;
            }

            div:nth-child(3) {
                transform: rotate(-45deg) translateY(-17px) translateX(6px);
                width: 54px;
                background: #67696a;
                height: 6px;
                border-top: 2px solid white;
                border-bottom: 2px solid white;
            }

            &:hover {
                transform: scale(0.9);
            }
        }
    }

    .menu-toggler-line-1 {

    }


    .nav > li {
        position: relative;
    }

    .nav > li:hover
    {
        a {
            color: #fff !important;
        }

        > a:after {
            background: #949494;
        }
    }

    .nav > li.current-menu-item {
        a {
            color: #fff !important;
        }

        > a:after {
            background: $primary;
        }
    }

    .nav > li > a:after {
        content: '';
        position: absolute;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        z-index:-1;
        transform: skew(-15deg);
        background: #fff;
        @include transition();
        border:1px solid #fff;
    }

    #big-menu-wrapper {
        position: fixed;
        z-index: 999;
        top: -100vh;
        left: 0px;
        height: calc( 100vh - 97px );
        width: 100%;
        @include transition(all 0.25s);
        background: $primary;

        &.opened {
            top: 97px;
        }

        #big-menu {
            a {
                text-decoration: none;
                color: #fff;
                font-family: 'Bebas Neue',sans-serif;
                -webkit-text-stroke: 1px transparent;
                color: #fff;
                @include transition();

                &:hover,
                &.active {
                    -webkit-text-stroke: 1px #fff;
                    color: transparent;
                };

                @include media-breakpoint-down(lg) {
                    font-size: 3rem
                }
            }
        }

        #sipario {
            position: relative;

            > div {

                z-index:10;
                position: absolute;
                left: 0px;
                top: 0px;

                &.opened {
                    z-index: 100 !important;

                    &:after {
                        right: 80%;
                        left: -20%;
                        transform: skew(-15deg);
                    }
                }

                &:after {
                    background: $primary;
                    content: '';
                    z-index: 999999999;
                    position: absolute;
                    top:0px;
                    right: -20%;
                    bottom: 0px;
                    left: -20%;
                    @include transition(all 0.5s);
                    transform: skew(-0deg);
                }
            }
        }

    }
}
